import React, { memo, useEffect, useState } from 'react'
import { Layout, message, Spin } from 'antd'
import { FacebookFilled, YoutubeFilled, TwitterSquareFilled, HomeFilled, MailFilled } from '@ant-design/icons'
import { MobileView, BrowserView } from 'react-device-detect'

import { getIPAddress, getUserAgent } from './utils/actions'

import agiliteFullLogo from '../core/resources/agilite-logo-full-web.png'
import agiliteLogo from '../core/resources/agilite-logo.ico'
import theme from './resources/theme'

const { Header, Content, Footer } = Layout

function TempRootContent () {
  const [IP, setIP] = useState('')
  const [userAgent, setUserAgent] = useState('')
  const [IPLoading, setIPLoading] = useState(false)
  const [UALoading, setUALoading] = useState(false)

  useEffect(() => {
    setIPLoading(true)
    setUALoading(true)

    getIPAddress((err, res) => {
      if (err) {
        if (err.response.data.errorMessage) {
          message.error(err.response.data.errorMessage)
        } else {
          message.error('Unknown Error Occured')
        }

        setIPLoading(false)
        setUALoading(false)
      } else {
        setIP(res.data)
        setIPLoading(false)

        getUserAgent((err, res) => {
          if (err) {
            if (err.response.data.errorMessage) {
              message.error(err.response.data.errorMessage)
            } else {
              message.error('Unknown Error Occured')
            }

            setUALoading(false)
          } else {
            setUserAgent(res.data)
            setUALoading(false)
          }
        })
      }
    })
  }, [])

  return (
    <Layout>
      <MobileView>
        <Header style={{ color: theme.primary, backgroundColor: theme.secondaryLight, marginBottom: 250, fontFamily: 'Avenir-Medium' }}>
          <center>
            <img src={agiliteFullLogo} alt='Agilit-e Full Logo' style={{ width: 200, height: 90 }} />
            <p style={{ fontSize: 40, fontWeight: 'bold' }}>Check My IP</p>
          </center>
        </Header>
      </MobileView>
      <BrowserView>
        <Header style={{ color: theme.primary, backgroundColor: theme.secondaryLight, marginBottom: 250, fontFamily: 'Avenir-Medium' }}>
          <center>
            <img src={agiliteFullLogo} alt='Agilit-e Full Logo' style={{ width: 350, height: 150 }} />
            <p style={{ fontSize: 40, fontWeight: 'bold' }}>Check My IP</p>
          </center>
        </Header>
      </BrowserView>
      <Content>
        <center>
          <span style={{ fontSize: 35, fontWeight: 'bold' }}>Your IP Address: {IPLoading ? <Spin size='small' style={{ marginLeft: 10 }} /> : IP}</span>
          <p style={{ fontSize: 20, fontWeight: 'bold', marginTop: 50 }}>Your User Agent Details:</p>
          <span style={{ fontSize: 20 }}>{UALoading ? <Spin size='small' /> : userAgent}</span>
          <p style={{ fontSize: 20, fontWeight: 'bold', marginTop: 50 }}>Social Media:</p>
          <div>
            <a style={{ margin: 10 }} href='https://agilite.io' target='_blank' rel='noopener noreferrer'><HomeFilled style={{ fontSize: 25, color: theme.primary }} /></a>
            <a style={{ margin: 10 }} href='mailto:info@agilite.io' target='_blank' rel='noopener noreferrer'><MailFilled style={{ fontSize: 25, color: theme.primary }} /></a>
            <a style={{ margin: 10 }} href='https://www.youtube.com/channel/UCFgN9BRpvEVLPzEQYJQ15eA' target='_blank' rel='noopener noreferrer'><YoutubeFilled style={{ fontSize: 25, color: '#bb0000' }} /></a>
            <a style={{ margin: 10 }} href='https://www.facebook.com/agilitehub' target='_blank' rel='noopener noreferrer'><FacebookFilled style={{ fontSize: 25, color: '#3b5998' }} /></a>
            <a style={{ margin: 10 }} href='https://twitter.com/AgiliteHub' target='_blank' rel='noopener noreferrer'><TwitterSquareFilled style={{ fontSize: 25, color: '#00aced' }} /></a>
          </div>
        </center>
      </Content>
      <MobileView>
        <Footer>
          <center>
            <a href='https://agilite.io' target='_blank' rel='noopener noreferrer' style={{ color: theme.secondaryDark }}>
              <img src={agiliteLogo} alt='Agilit-e Logo' style={{ width: 50, height: 50 }} /> Powered By Agilit-e
            </a>
          </center>
        </Footer>
      </MobileView>
      <BrowserView>
        <Footer style={{ position: 'fixed', bottom: 10, width: '100%' }}>
          <center>
            <a href='https://agilite.io' target='_blank' rel='noopener noreferrer' style={{ color: theme.secondaryDark }}>
              <img src={agiliteLogo} alt='Agilit-e Logo' style={{ width: 50, height: 50 }} /> Powered By Agilit-e
            </a>
          </center>
        </Footer>
      </BrowserView>
    </Layout>
  )
}

const RootContent = memo(TempRootContent)
export default RootContent
