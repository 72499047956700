import Agilite from 'agilite'
import Config from './config'

export const getIPAddress = async (callback) => {
  let agilite = null
  let result = null

  try {
    agilite = new Agilite({
      apiServerUrl: Config.apiServerUrl,
      apiKey: Config.apiKey
    })

    result = await agilite.Utils.getIP()

    callback(null, result)
  } catch (error) {
    callback(error)
  }
}

export const getUserAgent = async (callback) => {
  let agilite = null
  let result = null

  try {
    agilite = new Agilite({
      apiServerUrl: Config.apiServerUrl,
      apiKey: Config.apiKey
    })

    result = await agilite.Utils.getUserAgent()

    callback(null, result)
  } catch (error) {
    callback(error)
  }
}