export default {
  title: '',
  theme: {},
  user: null,
  enableOnUnloadPrompt: false,
  agilite: {
    apiServerUrl: '',
    apiKey: ''
  },
  appLogo: {
    image: null,
    title: '',
    href: ''
  }
}
